<!-- 作业详情 已做-->
<style lang="scss" scoped>
@import '@/assets/styles/questionsItem.scss';
*{
	box-sizing: border-box;
}
.page{
	height: 100%;
	overflow: auto;
	background-color: #FFFFFF;
	padding: 20px 0;
}
.questions{
	padding-top:1px !important;
	cursor: pointer;
	&:hover{
		background-color: #f9f7ff;
	}
}
.top_title{
	font-size: 18px;
    font-weight: bold;
    color: #222222;
	line-height: 50px;
	.score{
		margin-left: 10px;
		font-size: 14px;
		font-weight: initial;
	}
}
.content {
	padding: 0 32px 0;

	.paperName {
		font-size: 20px;
		color: #FF4D4F;
		font-weight: bold;
		line-height: 30px;
		text-align: center;
	}

	.papeDate {
		font-size: 16px;
		font-weight: 400;
		color: #444444;
		line-height: 35px;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.hint {
		width: 100%;
		height: 80px;
		background: #FFF8F1;
		border-radius: 14px;
		border: 1px solid $comOrange;
		padding: 0 26px;
		font-size: 28px;
		color: $comOrange;

		.iconimg {
			width: 40px;
			height: 40px;
			margin-right: 13px;
		}
	}

	.table {
		margin-top: 10px;
		border-radius: 8px;
		border: 1px solid #AAAAAA;
		width: 100%;
		margin-bottom: 20px;

		.tr {
			width: 100%;

			.td {
				width: 100%;
				padding: 7px 16px;
				line-height: 24px;
				border: 1px solid #AAAAAA;
				border-right: none;
				border-bottom: none;
				font-weight: 400;

				&:first-child{
					border-left: none;
				}
				.title {
					font-size: 14px;
					font-weight: 400;
					color: #888888;
				}

				.val {
					flex: 1;
					font-size: 14px;
					color: #3D3D3D;
					margin-left: 6px;
				}
			}

			&:first-child {
				.td {
					border-top: none;
				}
			}
		}
	}

	.photograph {
		margin-top: 16px;

		.h3 {
			height: 30px;
			font-size: 18px;
			font-weight: bold;
			color: #222222;
			line-height: 30px;
		}

		.images {
			margin-top: 10px;
			scroll-snap-type: x mandatory;
			width: 100%;
			display: flex;
			overflow-x: scroll;
			overflow-y: hidden;
			padding-bottom: 5px;
			.el-image{
				margin-right: 10px;
				flex-shrink: 0;
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}

	.remark {
		margin-top: 14px;
		width: 100%;
		background-color: #FFFFFF;
		border-radius: 14px;
		position: relative;

		.teacherRemark {
			width: 120px;
			height: 30px;
			background: rgba(255, 124, 18, 0.102);
			border-radius: 30px 30px 30px 0px;
			line-height: 30px;
			text-align: center;
			font-size: 16px;
			color: #FF7C12;
			font-weight: bold;
			
			.iconimg {
				width: 30px;
				margin-right: 5px;
			}
		}

		.value {
			font-size: 16px;
			line-height: 22px;
			color: #FF7C12;
			margin-top: 10px;
		}
	}
}

.solutionDrawImg{
	display: flex;
	padding:20px 32px;
	border-radius: 14px;
	.drawImg{
		margin: 0;
	}
	.iconfont{
		display: none !important;
	}
	.icona-dui{
		margin-right: 24px;
		font-size: 34px;
		font-weight: initial;
		color: $comGreen;
	}
	.icona-cuo{
		margin-right: 24px;
		font-size: 34px;
		font-weight: initial;
		color: $comRed;
	}
	&.correct{
		background: rgba(29,187,98,0.1);
		.icona-dui{
			display: inline-block;
		}
	}	
	&.mistake{
		background: rgba(255,77,79,0.1);
		.icona-cuo{
			display: inline-block;
		}
	}
}

.office{
	margin:0 auto;
	width: 800px;
}
</style>

<template>
    <div class="page">
		<div class="content">
			<div class="paperName">{{paperInfo.tepap_title}}</div>
			<div class="papeDate flex_content_center">
				（考试时间：{{ paperInfo.stexa_starttime | formatTime("MM月DD日 HH:mm") }} ~ {{ paperInfo.stexa_endtime | formatTime("HH:mm") }}
				<text class="c-blue ml-10" v-if="paperInfo.stexa_type==10">线上考试</text>
				<text class="c-orange ml-10" v-else>线下考试</text> ）
			</div>
			<!-- 线下拍照提示 -->
			<div class="hint flex_content_center" v-if="paperInfo.stexa_type==20&&paperInfo.stexa_status<30">
				<img class="iconimg" src="@assets/images/date.png" alt="">
				温馨提示：本次属于线下考试，请拍照上传！
			</div>
			<!-- 考试数据 -->
			<div class="table">
				<div class="tr flex_align">
					<div class="td flex_align">
						<div class="title">考试科目：</div>
						<div class="val c-2 fw-b" v-if="paperInfo.sysub_name">{{paperInfo.sysub_name}}</div>
					</div>
					<div class="td flex_align">
						<div class="title">满分：</div>
						<div class="val c-2 fw-b" v-if="paperInfo.tepap_sumscore">{{paperInfo.tepap_sumscore}}分</div>
					</div>
					<div class="td flex_align">
						<div class="title">监考老师：</div>
						<div class="val c-2 fw-b" v-if="paperInfo.teuse_name">{{paperInfo.teuse_name}}</div>
					</div>
					<div class="td flex_align">
						<div class="title">得分：</div>
						<div v-if="paperInfo.stexa_status==40">
							<div v-if="paperInfo.stexa_show==10">
								<div class="val fw-b c-orange fs-36" v-if="paperInfo.stexa_score>=0">{{paperInfo.stexa_score}}分</div>
								<div class="val fs-36" v-else>—</div>
							</div>
							<div v-else>
								<div class="val fw-b c-orange fs-36" v-if="paperInfo.stexa_grade">{{paperInfo.stexa_grade}}</div>
								<div class="val fs-36" v-else>—</div>
							</div>
						</div>
						<div v-else class="val fs-36">—</div>
					</div>
				</div>
				<div class="tr flex_align">
					<div class="td flex">
						<div class="title">答题状态：</div>
						<div class="val c-green" v-if="paperInfo.stexa_finishstatus==10">未开始答题</div>
						<div class="val c-orange" v-if="paperInfo.stexa_finishstatus==20">答题进行中</div>
						<div class="val c-blue" v-if="paperInfo.stexa_finishstatus==30">答题结束</div>
					</div>
				</div>
				<div class="tr flex_align" v-if="paperInfo.tepap_rule">
					<div class="td flex">
						<div class="title">考试说明：
						</div>
						<div class="val">{{paperInfo.tepap_rule}}</div>
					</div>
				</div>
			</div>
			<!-- pdf展示数据--试卷 -->
			<div class="photograph" v-if="paperInfo.stexa_make_type==20">
				<div class="h3">试卷</div>
				<div class="office" v-if="office_paper_url">
					<vueOffice v-model="office_paper_url" type="pdf"></vueOffice>
				</div>
			</div>
			<div class="photograph" v-if="paperInfo.stexa_make_type==20">
				<div class="h3">试卷解析</div>
				<!-- pdf展示数据--解析 -->
				<div class="office" v-if="stexa_att_analysis_url">
					<vueOffice v-model="stexa_att_analysis_url" type="pdf"></vueOffice>
				</div>
			</div>
			<!-- 试卷图片 已解答展示 stexa_status==40 批阅后的试卷-->
			<div class="photograph" v-if="paperInfo.stexa_status==40&&paperInfo.stexa_teaimages.length>0">
				<div class="h3">试卷图片</div>
				<div class="images">
					<el-image style="width: 100px; height: 100px" :src="item" :preview-src-list="[item]" v-for="(item,index) in  paperInfo.stexa_teaimages" :key="index"></el-image>
				</div>
			</div>
			<!-- 未批阅的试卷 -->
			<div v-else>
				<div class="photograph" v-if="paperInfo.stexa_images.length>0">
					<div class="h3">试卷图片</div>
					<div class="images">
						<el-image style="width: 100px; height: 100px" :src="item" :preview-src-list="[item]" v-for="(item,index) in  paperInfo.stexa_images" :key="index"></el-image>
					</div>
				</div>
			</div>
			<!-- 老师评语  已解答展示-->
			<div class="remark" v-if="paperInfo.stexa_comment">
				<div class="teacherRemark flex_content_center">
					<img class="iconimg" src="@assets/images/studentpingyuicon.png" alt="">
					老师评语
				</div>
				<div class="value">{{paperInfo.stexa_comment}}</div>
			</div>
			<div v-for="(item1,index1) in homework_list" :key="index1">
				<div class="top_title flex_align">
					{{ index1 + 1 | changeChinesNum }}、{{item1.stexa_pap_top_title}}
					<div class="score">
						(共 {{item1.question.length}}道 共 {{item1.stexa_pap_top_sumscore}} 分)
					</div>
				</div>
				<div v-for="(item2,index2) in item1.question" :key="index2">
					<div class="questions" @click="goDetails(index1,index2)">
						<div class="topic" v-if="item2.syque_typ_id!=14">
							<div class="flex"> &nbsp; <span>{{ index2 + 1 > 9 && index2 + 1 || `0${index2 + 1}` }}.</span>、<span class="mold">{{item2.syque_typ_name}} </span>  <div class="val"> <em class="richflex" v-html="item2.stexa_que_title"></em> <span>({{item2.stexa_que_score}}分)</span></div></div> 
							<img class="selectimg" v-if="item2.stexa_que_title_image" :src="item2.stexa_que_title_image" alt="">
							<!-- <el-image v-if="item2.stexa_que_title_image" style="width: 100px; height: 100px" :src="item2.stexa_que_title_image" :preview-src-list="[item2.stexa_que_title_image]"></el-image> -->
						</div>
						<!-- syque_typ_id   1单项选择 2多项选择 3单项填空 4多项填空 5判断 6计算 7简答 8问答 -->
						<!-- 单选和判断 -->
						<div v-if="item2.syque_typ_id==1||item2.syque_typ_id==5">
							<div v-for="(item,index) in item2.question_item" :key="index">
								<!-- 判断单题的答案下标位置是否等于当前题目的选项值-->
								<div class="li" :class="[getopt(item.stexa_que_ite_code,item2.stexa_que_stuanswer)?'opt':'',getopt(item.stexa_que_ite_code,item2.stexa_que_stuanswer)&&item2.stexa_que_stu_isanswer==1&&paperInfo.stexa_status==40?(item.stexa_que_ite_isanswer==10?'correct':'mistake'):'']">
									<div v-if="item.stexa_que_ite_title">
										<div class="flex">
											<div class="iconfont icona-dui">&#xe6db;</div>
											<div class="iconfont icona-cuo">&#xe6dc;</div>
											<div class="number">{{item.stexa_que_ite_code}}.</div>
											<div class="val">
												<div class="richinline" v-html="item.stexa_que_ite_title"></div>
											</div>
										</div>
										<img class="selectimg" v-if="item.stexa_que_ite_image" :src="item.stexa_que_ite_image" alt="">
										<!-- <el-image v-if="item.stexa_que_ite_image" style="width: 100px; height: 100px" :src="item.stexa_que_ite_image" :preview-src-list="[item.stexa_que_ite_image]"></el-image> -->
									</div>
									<div class="flex" v-else>
										<div class="iconfont icona-dui">&#xe6db;</div>
										<div class="iconfont icona-cuo">&#xe6dc;</div>
										<div class="number">{{item.stexa_que_ite_code}}.</div>
										<img class="selectimg" v-if="item.stexa_que_ite_image" :src="item.stexa_que_ite_image" alt="">
										<!-- <el-image v-if="item.stexa_que_ite_image" style="width: 100px; height: 100px;margin-top:0;" :src="item.stexa_que_ite_image" :preview-src-list="[item.stexa_que_ite_image]"></el-image> -->
									</div>
								</div>
							</div>
							<!-- 学生选择对错 -->
							<iJudge v-if="paperInfo.stexa_type==20&&paperInfo.stexa_status==40" :item="item2" :index1="index1" :index2="index2" @update_question="update_question"></iJudge>
						</div>
						<!-- 多选 -->
						<div v-if="item2.syque_typ_id==2">
							<div v-for="(item,index) in item2.question_item" :key="index">
								<!-- 判断单题的答案下标位置是否等于当前题目的选项值-->
								<div class="li" :class="[getopt(item.stexa_que_ite_code,item2.stexa_que_stuanswer)?'opt':'',getopt(item.stexa_que_ite_code,item2.stexa_que_stuanswer)&&item2.stexa_que_stu_isanswer==1&&paperInfo.stexa_status==40?(item.stexa_que_ite_isanswer==10?'correct':'mistake'):'']">
									<div v-if="item.stexa_que_ite_title">
										<div class="flex">
											<div class="iconfont icona-dui">&#xe6db;</div>
											<div class="iconfont icona-cuo">&#xe6dc;</div>
											<div class="number">{{item.stexa_que_ite_code}}.</div>
											<div class="val">
												<div class="richinline" v-html="item.stexa_que_ite_title"></div>
											</div>
										</div>
										<img class="selectimg" v-if="item.stexa_que_ite_image" :src="item.stexa_que_ite_image" alt="">
										<!-- <el-image v-if="item.stexa_que_ite_image" style="width: 100px; height: 100px;" :src="item.stexa_que_ite_image" :preview-src-list="[item.stexa_que_ite_image]"></el-image> -->
									</div>
									<div class="flex" v-else>
										<div class="iconfont icona-dui">&#xe6db;</div>
										<div class="iconfont icona-cuo">&#xe6dc;</div>
										<div class="number">{{item.stexa_que_ite_code}}.</div>
										<img class="selectimg" v-if="item.stexa_que_ite_image" :src="item.stexa_que_ite_image" alt="">
										<!-- <el-image v-if="item.stexa_que_ite_image" style="width: 100px; height: 100px;margin-top:0;" :src="item.stexa_que_ite_image" :preview-src-list="[item.stexa_que_ite_image]"></el-image> -->
									</div>
								</div>
							</div>
							<!-- 学生选择对错 -->
							<iJudge v-if="paperInfo.stexa_type==20&&paperInfo.stexa_status==40" :item="item2" :index1="index1" :index2="index2" @update_question="update_question"></iJudge>
						</div>
						<!-- 单项填空 -->
						<div v-if="item2.syque_typ_id==3">
							<div v-if="item2.stexa_que_stuanswer[0]">
								<div class="li flex" :class="paperInfo.stexa_type==10&&paperInfo.stexa_status==40?(item2.stexa_que_isstustatus==10?'correct':'mistake'):''">
									<div class="iconfont icona-dui">&#xe6db;</div>
									<div class="iconfont icona-cuo">&#xe6dc;</div>
									<div class="val" v-if="item2.stexa_que_stuanswer[0]"><em class="richinline" v-html="item2.stexa_que_stuanswer[0]"></em></div>
								</div>
							</div>
							<div class="solutionDrawImg" :class="item2.stexa_que_isstustatus==10?'correct':'mistake'" v-if="item2.stexa_que_stuanswer_image&&paperInfo.stexa_type==10">
								<div v-if="paperInfo.stexa_status==40">
									<div class="icon icona-dui" v-if="item2.stexa_que_isstustatus==10"></div>
									<div class="icon icona-cuo" v-else></div>
								</div>
								<img class="selectimg" v-if="item2.stexa_que_stuanswer_image" :src="item2.stexa_que_stuanswer_image" alt="">
								<!-- <el-image v-if="item2.stexa_que_stuanswer_image" style="width: 105px; height: 150px;" :src="item2.stexa_que_stuanswer_image" :preview-src-list="[item2.stexa_que_stuanswer_image]"></el-image> -->
							</div>
							<!-- 学生选择对错 -->
							<iJudge v-if="paperInfo.stexa_type==20&&paperInfo.stexa_status==40" :item="item2" :index1="index1" :index2="index2" @update_question="update_question"></iJudge>
						</div>
						<!-- 多项填空 -->
						<div v-if="item2.syque_typ_id==4">
							<div class="fs-28 c-8 mt-57 mb-32">请依次将填空题答案输入到下框中</div>
							<div v-if="isshowitem(item2.stexa_que_stuanswer)">
								<div v-for="(item,index) in item2.question_item" :key="index">
									<div class="li flex" :class="[paperInfo.stexa_type==10&&paperInfo.stexa_status==40?(item2.stexa_que_isstustatus==10?'correct':'mistake'):'',index<item2.question_item-1?'mb-0':'']">
										<div class="icon icona-dui" :style="index>0?'opacity: 0;':''"></div>
										<div class="icon icona-cuo" :style="index>0?'opacity: 0;':''"></div>
										<div class="val" v-if="item2.stexa_que_stuanswer[index]"><em class="richinline" v-html="item2.stexa_que_stuanswer[index]"></em> </div>
									</div>
								</div>
							</div>
							<div class="solutionDrawImg" :class="item2.stexa_que_isstustatus==10?'correct':'mistake'" v-if="item2.stexa_que_stuanswer_image&&paperInfo.stexa_type==10">
								<div v-if="paperInfo.stexa_status==40">
									<div class="icon icona-dui" v-if="item2.stexa_que_isstustatus==10"></div>
									<div class="icon icona-cuo" v-else></div>
								</div>
								<img class="selectimg" v-if="item2.stexa_que_stuanswer_image" :src="item2.stexa_que_stuanswer_image" alt="">
								<!-- <el-image v-if="item2.stexa_que_stuanswer_image" style="width: 105px; height: 150px;" :src="item2.stexa_que_stuanswer_image" :preview-src-list="[item2.stexa_que_stuanswer_image]"></el-image> -->
							</div>
							<!-- 学生选择对错 -->
							<iJudge v-if="paperInfo.stexa_type==20&&paperInfo.stexa_status==40" :item="item2" :index1="index1" :index2="index2" @update_question="update_question"></iJudge>
						</div>
						<!-- 8问答 -->
						<div v-if="item2.syque_typ_id==7||item2.syque_typ_id==8">
							<div class="li flex" v-if="item2.stexa_que_stuanswer[0]" :class="paperInfo.stexa_status==40?(item2.stexa_que_isstustatus==10?'correct':'mistake'):''">
								<div class="iconfont icona-dui">&#xe6db;</div>
								<div class="iconfont icona-cuo">&#xe6dc;</div>
								<div class="val" v-if="item2.stexa_que_stuanswer[0]">
									<div class="richblock" v-html="item2.stexa_que_stuanswer[0]"></div>
								</div>
								<div class="val" v-else> </div>
							</div>
							<div class="solutionDrawImg" :class="item2.stexa_que_isstustatus==10?'correct':'mistake'" v-if="item2.stexa_que_stuanswer_image&&paperInfo.stexa_type==10">
								<div v-if="paperInfo.stexa_status==40">
									<div class="icon icona-dui" v-if="item2.stexa_que_isstustatus==10"></div>
									<div class="icon icona-cuo" v-else></div>
								</div>
								<img class="selectimg" v-if="item2.stexa_que_stuanswer_image" :src="item2.stexa_que_stuanswer_image" alt="">
								<!-- <el-image v-if="item2.stexa_que_stuanswer_image" style="width: 105px; height: 150px;" :src="item2.stexa_que_stuanswer_image" :preview-src-list="[item2.stexa_que_stuanswer_image]"></el-image> -->
							</div>
							<!-- 学生选择对错 -->
							<iJudge v-if="paperInfo.stexa_type==20&&paperInfo.stexa_status==40" :item="item2" :index1="index1" :index2="index2" @update_question="update_question"></iJudge>
						</div>
						<!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ 组合题 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
						<div v-if="item2.syque_typ_id==14">
							<div class="topic">
								<div class="val">
									<div class="richblock" v-html="item2.stexa_que_title"></div>
								</div>
								<img class="selectimg" v-if="item2.stexa_que_title_image" :src="item2.stexa_que_title_image" alt="">
								<!-- <el-image v-if="item2.stexa_que_title_image" style="width: 100px; height: 100px;" :src="item2.stexa_que_title_image" :preview-src-list="[item2.stexa_que_title_image]"></el-image> -->
							</div>
							<div class="groupval">
								<div v-for="(item3,index3) in item2.question_item" :key="index3">
									<div class="topic">
										<div class="val"> <span>{{ index3 + 1 > 9 && index3 + 1 || `0${index3 + 1}` }}.</span>、<em class="richinline" v-html="item3.stexa_que_title"></em>
											<span v-if="item2.syque_typ_id==14">
												（{{item3.syque_typ_name}}）
											</span>
										</div>
										<img class="selectimg" v-if="item3.stexa_que_title_image" :src="item3.stexa_que_title_image" alt="">
										<!-- <el-image v-if="item3.stexa_que_title_image" style="width: 100px; height: 100px;" :src="item3.stexa_que_title_image" :preview-src-list="[item3.stexa_que_title_image]"></el-image> -->
									</div>
									<div v-if="item3.syque_typ_id==1||item3.syque_typ_id==2||item3.syque_typ_id==5">
										<div v-for="(item,index) in item3.question_child_item" :key="index">
											<!-- 判断单题的答案下标位置是否等于当前题目的选项值-->
											<div class="li" :class="[getopt(item.stexa_que_ite_code,item3.stexa_que_stuanswer)?'opt':'',getopt(item.stexa_que_ite_code,item3.stexa_que_stuanswer)&&item3.stexa_que_stu_isanswer==1&&paperInfo.stexa_type==10&&paperInfo.stexa_status==40?(item.stexa_que_ite_isanswer==10?'correct':'mistake'):'']">
												<div v-if="item.stexa_que_ite_title">
													<div class="flex">
														<div class="iconfont icona-dui">&#xe6db;</div>
														<div class="iconfont icona-cuo">&#xe6dc;</div>
														<div class="number">{{item.stexa_que_ite_code}}.</div>
														<div class="val">
															<div class="richinline" v-html="item.stexa_que_ite_title"></div>
														</div>
													</div>
													<img class="selectimg" v-if="item.stexa_que_ite_image" :src="item.stexa_que_ite_image" alt="">
													<!-- <el-image v-if="item.stexa_que_ite_image" style="width: 100px; height: 100px;" :src="item.stexa_que_ite_image" :preview-src-list="[item.stexa_que_ite_image]"></el-image> -->
												</div>
												<div class="flex" v-else>
													<div class="iconfont icona-dui">&#xe6db;</div>
													<div class="iconfont icona-cuo">&#xe6dc;</div>
													<div class="number">{{item.stexa_que_ite_code}}.</div>
													<img class="selectimg" v-if="item.stexa_que_ite_image" :src="item.stexa_que_ite_image" alt="">
													<!-- <el-image v-if="item.stexa_que_ite_image" style="width: 100px; height: 100px;margin-top:0;" :src="item.stexa_que_ite_image" :preview-src-list="[item.stexa_que_ite_image]"></el-image> -->
												</div>
											</div>
										</div>
									</div>
									<!-- 单项填空 -->
									<div v-if="item3.syque_typ_id==3">
										<div v-if="item3.stexa_que_stuanswer[0]">
											<div class="li flex" :class="paperInfo.stexa_type==10&&paperInfo.stexa_status==40?(item3.stexa_que_isstustatus==10?'correct':'mistake'):''">
												<div class="iconfont icona-dui">&#xe6db;</div>
												<div class="iconfont icona-cuo">&#xe6dc;</div>
												<div class="val" v-if="item3.stexa_que_stuanswer[0]">
													<div class="richinline" v-html="item3.stexa_que_stuanswer[0]"></div>
												</div>
											</div>
										</div>
										<div class="solutionDrawImg" :class="item3.stexa_que_isstustatus==10?'correct':'mistake'" v-if="item3.stexa_que_stuanswer_image&&paperInfo.stexa_type==10">
											<div v-if="paperInfo.stexa_status==40">
												<div class="icon icona-dui" v-if="item3.stexa_que_isstustatus==10"></div>
												<div class="icon icona-cuo" v-else></div>
											</div>
											<img class="selectimg" v-if="item3.stexa_que_stuanswer_image" :src="item3.stexa_que_stuanswer_image" alt="">
											<!-- <el-image v-if="item3.stexa_que_stuanswer_image" style="width: 105px; height: 150px;" :src="item3.stexa_que_stuanswer_image" :preview-src-list="[item3.stexa_que_stuanswer_image]"></el-image> -->
										</div>
									</div>
									<!-- 多项填空 -->
									<div v-if="item3.syque_typ_id==4">
										<div class="fs-28 c-8 mt-57 mb-32">请依次将填空题答案输入到下框中</div>
										<div v-if="isshowitem(item3.stexa_que_stuanswer)">
											<div v-for="(item,index) in item3.question_child_item" :key="index">
												<div class="li flex" :class="[paperInfo.stexa_type==10&&paperInfo.stexa_status==40?(item3.stexa_que_isstustatus==10?'correct':'mistake'):'',index<item3.question_child_item-1?'mb-0':'']">
													<div class="icon icona-dui" :style="index>0?'opacity: 0;':''"></div>
													<div class="icon icona-cuo" :style="index>0?'opacity: 0;':''"></div>
													<div class="val" v-if="item3.stexa_que_stuanswer[index]">
														<div class="richinline" v-html="item3.stexa_que_stuanswer[index]"></div>
													</div>
												</div>
											</div>
										</div>
										<div class="solutionDrawImg" :class="item3.stexa_que_isstustatus==10?'correct':'mistake'" v-if="item3.stexa_que_stuanswer_image&&paperInfo.stexa_type==10">
											<div v-if="paperInfo.stexa_status==40">
												<div class="icon icona-dui" v-if="item3.stexa_que_isstustatus==10"></div>
												<div class="icon icona-cuo" v-else></div>
											</div>
											<img class="selectimg" v-if="item3.stexa_que_stuanswer_image" :src="item3.stexa_que_stuanswer_image" alt="">
											<!-- <el-image v-if="item3.stexa_que_stuanswer_image" style="width: 105px; height: 150px;" :src="item3.stexa_que_stuanswer_image" :preview-src-list="[item3.stexa_que_stuanswer_image]"></el-image> -->
										</div>
									</div>
									<!--7计算 8问答 -->
									<div v-if="item3.syque_typ_id==7||item3.syque_typ_id==8">
										<div class="li flex" :class="paperInfo.stexa_status==40?(item3.stexa_que_isstustatus==10?'correct':'mistake'):''" v-if="item3.stexa_que_stuanswer[0]">
											<div class="iconfont icona-dui">&#xe6db;</div>
											<div class="iconfont icona-cuo">&#xe6dc;</div>
											<div class="val" v-if="item3.stexa_que_stuanswer[0]">
												<div class="richinline" v-html="item3.stexa_que_stuanswer[0]"></div>
											</div>
											<div class="val" v-else></div>
										</div>
										<div class="solutionDrawImg" :class="item3.stexa_que_isstustatus==10?'correct':'mistake'" v-if="item3.stexa_que_stuanswer_image&&paperInfo.stexa_type==10">
											<div v-if="paperInfo.stexa_status==40">
												<div class="icon icona-dui" v-if="item3.stexa_que_isstustatus==10"></div>
												<div class="icon icona-cuo" v-else></div>
											</div>
											<img class="selectimg" v-if="item3.stexa_que_stuanswer_image" :src="item3.stexa_que_stuanswer_image" alt="">
											<!-- <el-image v-if="item3.stexa_que_stuanswer_image" style="width: 105px; height: 150px;" :src="item3.stexa_que_stuanswer_image" :preview-src-list="[item3.stexa_que_stuanswer_image]"></el-image> -->
										</div>
									</div>
								</div>
							</div>	
							<!-- 学生选择对错 -->
							<iJudge v-if="paperInfo.stexa_type==20&&paperInfo.stexa_status==40" :item="item2" :index1="index1" :index2="index2" @update_question="update_question"></iJudge>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {stuexamination_info, stuexamination_start_answer,update_question } from "@api/home"
import iJudge from './components/iJudge.vue'
import vueOffice from "@comp/vueOffice"
export default {
    name: 'examinationFinish',
	components: {
		iJudge,
		vueOffice
	},
    data() {
        return {
			// 试卷id
			stexa_id: 0,
			//试卷简介内容
			paperInfo: {
				stexa_images:[],
				stexa_status:10,//30=已结束/待审批,40=审批完成
				stexa_type:10,//10线上 20线下
			},
			//试卷答题
			homework_list:[],
			// 试卷pdf
			office_paper_url:"",
			// 解析pdf
			stexa_att_analysis_url:"",
        }
    },
    created() {
        this.stexa_id=this.$route.params.id
        this.getData();
    },
    methods: {
       
        async getData() {
            let { data } = await stuexamination_info({stexa_id:this.stexa_id});
			this.paperInfo = data
			this.office_paper_url = data.stexa_att_paper_url
			this.stexa_att_analysis_url = data.stexa_att_analysis_url
            this.$forceUpdate();
			if(data.stexa_make_type==10){
				this.getAnswer()
			}
        },
        async getAnswer() {
            let { data } = await stuexamination_start_answer({stexa_id:this.stexa_id});
			this.homework_list=data
            this.$forceUpdate();
        },
       	//判断试题是否选中 answer答案  list
		getopt(answer,list){
			if(answer&&list&&list.length>0){
				return list.includes(answer)
			}else{
				return false
			}
		},
		// 跳转详情
		goDetails(index1,index2){
			if(this.paperInfo.stexa_status==40){
				this.$router.push({
                    name:"EXAM_INATION_DETAILS_ITEM",
                    params: {
                        index1:index1,
                        index2:index2,
                        id:this.stexa_id
                    }
                })
			}
		},
		//手动标识题目状态
		async update_question({item,index1,index2}){
            let res = await update_question({
				stexa_que_id:item.stexa_que_id,
				stexa_que_isstustatus:item.stexa_que_isstustatus
			});
			let homework_list_item=this.homework_list[index1]
			homework_list_item.question[index2]=item
			this.homework_list[index1]=homework_list_item
            this.$forceUpdate();
		},
		//判断列表里面是否有填写
		isshowitem(list){
			let isshows = list.some(item=>{
				return item.length>0
			})
			return isshows
		}
      
    },
}
</script>